<template>
    <b-overlay :show="loading">
        <b-row class="justify-content-center">
            <b-col sm="12" md="10" lg="9">
                <b-card>
                    <b-form-group label="Nama Instansi" class="mb-2">
                        <b-form-input v-model="setting.instansi" placeholder="Ex: PT. NAMA PERUSAHAAN"></b-form-input>
                    </b-form-group>
                    <b-form-group v-if="setting.path" label="Logo" class="mb-2">
                        <div>
                            <img :src="setting.path" style="width: 70px;" alt="" class="d-block mb-1" />
                            <input type="file" @change="onSelectedLogo" />
                        </div>
                    </b-form-group>
                    <b-form-group label="Jenis Usaha" class="mb-2">
                        <b-form-input v-model="setting.jenis_usaha" placeholder="Ex: Pertambangan"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Website" class="mb-2">
                        <b-form-input v-model="setting.website" placeholder="Ex: www.perusahaanmu.co.id"></b-form-input>
                    </b-form-group>
                    <b-form-group label="Alamat" class="mb-2">
                        <quill-editor v-model="setting.alamat" :options="editorOptions" />
                    </b-form-group>
                    
                    <b-button class="text-right" variant="primary" @click.prevent="submit">
                        Simpan
                    </b-button>
                </b-card>
            </b-col>
        </b-row>
    </b-overlay>
</template>
<script>
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
import {BOverlay, BButton, BCard, BRow, BCol, BFormGroup, BFormInput} from 'bootstrap-vue'

export default {
    components: {
        BOverlay, BButton, BCard, BRow, BCol, BFormGroup, BFormInput, quillEditor
    },
    data: () => ({
        loading: false,
        setting: {
            id: null,
            instansi: null,
            logo: null,
            jenis_usaha: null,
            alamat: null,
            website: null,
            path: null
        },
        editorOptions: {
            theme: 'bubble',
            syntax: false,
            modules: {
                toolbar: [
                    ['bold', 'italic'],
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['link'],
                ],
            }

        }
    }),
    methods: {
        onSelectedLogo(e) {
            this.setting.logo = e.target.files[0]
        },
        async submit() {
            const fd = new FormData()
            for(let key in this.setting) {
                if(this.setting[key]) {
                    fd.append(key, this.setting[key])
                }
            }

            try {
                this.loading = true
                await this.$store.dispatch('setting/save', fd)
                this.loading = false
                this.displaySuccess({
                    message: 'Perubahan berhasil disimpan'
                })
                setTimeout(() => window.location.reload(), 1000)
            }
            catch(e) {
                this.loading = false
                this.displayError(e)
                return false
            }
        }
    },
    async created() {
        this.loading = true
        const setting = await this.$store.dispatch('setting/getData')
        this.setting = setting
        this.loading = false
    }
}
</script>